export enum BenRelationship {
  FA = 'FA',
  MO = 'MO',
  BR = 'BR',
  SI = 'SI',
  WI = 'WI',
  HU = 'HU',
  SO = 'SO',
  DA = 'DA'
}

export const mapBenRelationship = new Map<BenRelationship, string>([
  [BenRelationship.FA, 'Father'],
  [BenRelationship.MO, 'Mother'],
  [BenRelationship.BR, 'Brother'],
  [BenRelationship.SI, 'Sister'],
  [BenRelationship.WI, 'Wife'],
  [BenRelationship.HU, 'Husband'],
  [BenRelationship.SO, 'Son'],
  [BenRelationship.DA, 'Daughter']
])

export enum BenGender {
  M = 'M',
  F = 'F'
}

export const mapBenGender = new Map<BenGender, string>([
  [BenGender.M, 'Male'],
  [BenGender.F, 'Female']
])

export enum BenNation {
  VN = 'VN'
  // US = 'US',
}
export const mapBenNation = new Map<BenNation, string>([[BenNation.VN, 'Việt Nam']])

export enum BenIDCard {
  NATIONAL_ID = 'ID',
  PASSPORT = 'PP',
  BIRTH_CERTIFICATE = 'BC'
}

export const BenIDCardOption = (mapper: (val: string) => string) => [
  {
    value: BenIDCard.NATIONAL_ID,
    label: mapper(BenIDCard.NATIONAL_ID)
  },
  {
    value: BenIDCard.PASSPORT,
    label: mapper(BenIDCard.PASSPORT)
  },
  {
    value: BenIDCard.BIRTH_CERTIFICATE,
    label: mapper(BenIDCard.BIRTH_CERTIFICATE)
  }
]

export const Gender = [
  {
    value: 'M',
    nameVi: 'Nam',
    nameEn: 'Male'
  },
  {
    value: 'F',
    nameVi: 'Nữ',
    nameEn: 'Female'
  }
]

export const NationalDocumentOPTIONS: Array<{ code: string; nameEn: string; nameVi: string }> = [
  {
    code: 'ND001',
    nameEn: 'Cục Trưởng Cục Cảnh Sát Quản lý Hành Chính về Trật tự Xã hội',
    nameVi: 'Cục Trưởng Cục Cảnh Sát Quản lý Hành Chính về Trật tự Xã hội'
  },
  {
    code: 'ND002',
    nameEn: 'Cục Trưởng Cục Cảnh Sát DKQL CƯ TRÚ VÀ DLQG VỀ DÂN CƯ',
    nameVi: 'Cục Trưởng Cục Cảnh Sát DKQL CƯ TRÚ VÀ DLQG VỀ DÂN CƯ'
  },
  {
    code: 'ND003',
    nameEn: 'BỘ CÔNG AN',
    nameVi: 'BỘ CÔNG AN'
  },
  {
    code: 'ND004',
    nameEn: 'Administration of Immigration',
    nameVi: 'Cục Quản lý xuất nhập cảnh'
  }
]
