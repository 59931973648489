import * as api from '../task-detail-api'
import { TransactionType } from '../TransactionType'
import { Customer } from '../Client'
import { SourceType } from '../SourceType'
import moment from 'moment'
import { getGivenName, getSurname } from '../../format'
import { InfowithCode, InfowithIDCode } from './common/generalInfo'
import { SubmissionService } from '../../SubmissionService'
import { PulseOpsFormat } from '../../../Formatter'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { BeneficiaryDesignationData } from '../BeneficiaryDesignation'

export interface CurrentBeneficiaryInfo {
  clientNumber: string
  clientName: string
  birthday: Date
  percentage: number
  mainLifeAssuredRelationship: string
}
export interface NewBeneficiaryInfo {
  clientName: string
  documentType: string
  nationalID: string
  issuedBy: string
  relationshipWithLifeAssured: string
  surname: string
  givenName: string
  dob: string
  gender: string
  nationality: string
  mobile: {
    code: string
    number: string
  } | null
  email: string
  percentage: number
  occupation?: string
  countryName?: string
  city?: string
  district?: string
  ward?: string
  address?: string
  foreignAddress?: string
  foreignCountry?: string
  nationality2?: string
  districtName?: string
  wardName?: string,
  existAttachments?: boolean | undefined | null
}
export const NewBeneficiaryInfo = (data: NewBeneficiaryInfo) => data

export interface ChangeBeneficialInfo {
  tag: TransactionType.BENEFICIARY_DESIGNATION
  clientName: string
  currentBen: CurrentBeneficiaryInfo[]
  newBen: NewBeneficiaryInfo[]
  seaBankWarningMsg: string
  sourceType: string
}
export const ChangeBeneficialInfo = (
  detail: api.ChangeBeneficialInfo,
  client: Customer,
  poName: string,
  clientQuery: Array<SubmissionService.ClientQuery>,
  countries: InfowithIDCode[],
  provinces: InfowithCode[],
  seaBankWarningMsg: string
): ChangeBeneficialInfo => {
  return {
    tag: TransactionType.BENEFICIARY_DESIGNATION,
    clientName: poName || client.body.name,
    currentBen: [],
    newBen: getNewBeneficiary(detail, client, clientQuery, countries, provinces),
    seaBankWarningMsg: seaBankWarningMsg,
    sourceType: detail.source
  }
}

const getClientData = (
  id: string | null | undefined,
  clientQuery: Array<SubmissionService.ClientQuery>
): SubmissionService.ClientQuery | null =>
  id && clientQuery.length ? clientQuery.find((client) => client.clientId === id) ?? null : null

const getNewBeneficiary = (
  detail: api.ChangeBeneficialInfo,
  client: Customer,
  clientQuery: Array<SubmissionService.ClientQuery>,
  countries: InfowithIDCode[],
  provinces: InfowithCode[]
): NewBeneficiaryInfo[] => {
  switch (detail.source) {
    case SourceType.PRUONLINE:
      return detail.payload.body.map((c) => {
        const clientData = getClientData(c.customer.clientId, clientQuery)
        return NewBeneficiaryInfo({
          clientName: c.customer.surName?.trim() + ' ' + c.customer.firstName?.trim(),
          documentType:
            c.customer.externalIds && c.customer.externalIds.SOE
              ? c.customer.externalIds.SOE ?? ''
              : clientData?.externalIds?.SOE ?? 'National ID',
          nationalID: c.customer.externalIds?.SOE_VALUE ?? clientData?.externalIds?.SOE_VALUE ?? '',
          issuedBy:
            c.customer.externalIds && c.customer.externalIds.ISSUED_BY
              ? provinces.find((x) => x.code === c.customer.externalIds?.ISSUED_BY)?.name || '-'
              : '-',
          relationshipWithLifeAssured: c.relationshipType,
          surname: c.customer.surName ?? '',
          givenName: c.customer.firstName ?? '',
          dob: c.customer.dob ? PulseOpsFormat.dateStr(c.customer.dob) : moment(clientData?.dob).format('DD/MM/YYYY'),
          gender: c.customer.sex ? c.customer.sex : clientData && clientData.sex ? clientData.sex : '',
          nationality: c.customer.nationality
            ? countries.find((e) => e.code === c.customer.nationality)?.name ?? ''
            : clientData && clientData.nationality
              ? clientData.nationality
              : '',
          mobile: {
            code: c.customer.contactDetails.PHONE.countryCode,
            number: c.customer.contactDetails.PHONE.value
              ? `+${c.customer.contactDetails.PHONE.countryCode} ${c.customer.contactDetails.PHONE.value}`
              : '-'
          },
          email: c.customer.contactDetails.EMAIL.value,
          percentage: c.percentage,
          occupation: c.customer.occupation ?? '',
          countryName: c.customer.attributesExt?.countryName ?? '',
          city: c.customer.addressDetails?.ADDRESS?.city ?? '',
          district: c.customer.addressDetails?.ADDRESS?.district ?? '',
          ward: c.customer.addressDetails?.ADDRESS?.subDistrict ?? '',
          address: c.customer.addressDetails?.ADDRESS?.line1 ?? '',
          foreignAddress: c.customer.addressDetails?.FOREIGN_ADDRESS?.line1 ?? '',
          foreignCountry: pipe(
            countries.find((x) => x.code === c.customer.addressDetails?.FOREIGN_ADDRESS?.countryCode),
            O.fromNullable,
            O.map((item) => item.name),
            O.getOrElse(() => '')
          ),
          nationality2: pipe(
            countries.find((x) => x.code === c.customer.attributesExt?.NATIONALITY_2),
            O.fromNullable,
            O.map((item) => item.name),
            O.getOrElse(() => '')
          ),
          districtName: c.customer.attributesExt?.districtName ?? '',
          wardName: c.customer.attributesExt?.wardName ?? ''
        })
      })

    case SourceType.ZALO: {
      return detail.payload.body.map((c) => {
        const clientData = getClientData(c.customer.clientId, clientQuery)
        return NewBeneficiaryInfo({
          clientName: c.customer.surName?.trim() + ' ' + c.customer.firstName?.trim(),
          documentType:
            c.customer.externalIds && c.customer.externalIds.SOE
              ? c.customer.externalIds.SOE ?? ''
              : clientData?.externalIds?.SOE ?? 'National ID',
          nationalID: c.customer.externalIds?.SOE_VALUE ?? clientData?.externalIds?.SOE_VALUE ?? '',
          issuedBy:
            c.customer.externalIds && c.customer.externalIds.ISSUED_BY
              ? provinces.find((x) => x.code === c.customer.externalIds?.ISSUED_BY)?.name || '-'
              : '-',
          relationshipWithLifeAssured: c.relationshipType,
          surname: c.customer.surName ?? '',
          givenName: c.customer.firstName ?? '',
          dob: c.customer.dob ? PulseOpsFormat.dateStr(c.customer.dob) : moment(clientData?.dob).format('DD/MM/YYYY'),
          gender: c.customer.sex ? c.customer.sex : clientData && clientData.sex ? clientData.sex : '',
          nationality: c.customer.nationality
            ? countries.find((e) => e.code === c.customer.nationality)?.name ?? ''
            : clientData && clientData.nationality
              ? clientData.nationality
              : '',
          mobile: {
            code: c.customer.contactDetails.PHONE?.countryCode ?? '-',
            number: c.customer.contactDetails.PHONE?.value
              ? `+${c?.customer.contactDetails.PHONE?.countryCode ?? ''} ${c.customer.contactDetails.PHONE?.value ?? ''
              }`
              : '-'
          },
          email: c.customer.contactDetails.EMAIL?.value ?? '-',
          percentage: c.percentage,
          occupation: c.customer.occupation ?? '',
          countryName: c.customer.attributesExt?.countryName ?? '',
          city: c.customer.addressDetails?.ADDRESS?.city ?? '',
          district: c.customer.addressDetails?.ADDRESS?.district ?? '',
          ward: c.customer.addressDetails?.ADDRESS?.subDistrict ?? '',
          address: c.customer.addressDetails?.ADDRESS?.line1 ?? '',
          foreignAddress: c.customer.addressDetails?.FOREIGN_ADDRESS?.line1 ?? '',
          foreignCountry: pipe(
            countries.find((x) => x.code === c.customer.addressDetails?.FOREIGN_ADDRESS?.countryCode),
            O.fromNullable,
            O.map((item) => item.name),
            O.getOrElse(() => '')
          ),
          nationality2: pipe(
            countries.find((x) => x.code === c.customer.attributesExt?.NATIONALITY_2),
            O.fromNullable,
            O.map((item) => item.name),
            O.getOrElse(() => '')
          ),
          districtName: c.customer.attributesExt?.districtName ?? '',
          wardName: c.customer.attributesExt?.wardName ?? '',
          existAttachments: c.customer.attributesExt?.existAttachments ?? false
        })
      })
    }

    case SourceType.PULSE4OPS:

      return detail.payload.body.map((c) => {
        const customedIssuedBy = pipe(
          c.customer.externalIds,
          O.fromNullable,
          O.fold(
            () => '-',
            (success) => pipe(
              success.ISSUED_BY,
              O.fromNullable,
              O.fold(
                () => '-',
                (dataItem) => pipe(
                  provinces.find((x) => x.code === dataItem),
                  O.fromNullable,
                  O.fold(
                    () => pipe(
                      BeneficiaryDesignationData.NationalDocumentOPTIONS.find((p) => p.code === dataItem),
                      O.fromNullable,
                      O.fold(
                        () => '-',
                        (res) => res.nameVi
                      )
                    ),
                    (item) => item.name
                  )
                )
              )
            )
          )
        )
        const clientData = getClientData(c.customer.clientId, clientQuery)
        return NewBeneficiaryInfo({
          clientName: c.customer.surName?.trim() + ' ' + c.customer.firstName?.trim(),
          documentType:
            c.customer.externalIds && c.customer.externalIds.SOE
              ? c.customer.externalIds.SOE ?? ''
              : clientData?.externalIds?.SOE ?? 'National ID',
          nationalID: c.customer.externalIds?.SOE_VALUE ?? clientData?.externalIds?.SOE_VALUE ?? '',
          // issuedBy:
          //   c.customer.externalIds && c.customer.externalIds.ISSUED_BY
          //     ? provinces.find((x) => x.code === c.customer.externalIds?.ISSUED_BY)?.name || '-'
          //     : '-',
          issuedBy: customedIssuedBy,
          relationshipWithLifeAssured: c.relationshipType,
          surname: c.customer.surName ?? '',
          givenName: c.customer.firstName ?? '',
          dob: c.customer.dob ? PulseOpsFormat.dateStr(c.customer.dob) : moment(clientData?.dob).format('DD/MM/YYYY'),
          gender: c.customer.sex ? c.customer.sex : clientData && clientData.sex ? clientData.sex : '',
          nationality: c.customer.nationality
            ? countries.find((e) => e.code === c.customer.nationality)?.name ?? ''
            : clientData && clientData.nationality
            ? clientData.nationality
            : '',
          mobile: {
            code: c.customer.contactDetails.PHONE.countryCode,
            number: c.customer.contactDetails.PHONE.value
              ? `+${c.customer.contactDetails.PHONE.countryCode} ${c.customer.contactDetails.PHONE.value}`
              : '-'
          },
          email: c.customer.contactDetails.EMAIL.value,
          percentage: c.percentage,
          occupation: c.customer.occupation ?? '',
          countryName: c.customer.attributesExt?.countryName ?? '',
          // city: pipe(provinces.find(x => x.code === c.customer.addressDetails?.ADDRESS?.city), O.fromNullable, O.map((item) => item.name), O.getOrElse(() => '')),
          city: c.customer.addressDetails?.ADDRESS?.city ?? '',
          district: c.customer.addressDetails?.ADDRESS?.district ?? '',
          ward: c.customer.addressDetails?.ADDRESS?.subDistrict ?? '',
          address: c.customer.addressDetails?.ADDRESS?.line1 ?? '',
          foreignAddress: c.customer.addressDetails?.FOREIGN_ADDRESS?.line1 ?? '',
          foreignCountry: pipe(countries.find(x => x.code === c.customer.addressDetails?.FOREIGN_ADDRESS?.countryCode), O.fromNullable, O.map((item) => item.name), O.getOrElse(() => '')),
          nationality2: pipe(countries.find(x => x.code === c.customer.attributesExt?.NATIONALITY_2), O.fromNullable, O.map((item) => item.name), O.getOrElse(() => '')),
          districtName: c.customer.attributesExt?.districtName ?? '',
          wardName: c.customer.attributesExt?.wardName ?? ''
        })
      })
    case SourceType.PULSE:
      return detail.payload.body.map((c) => {
        const clientData = getClientData(c.customer.clientId, clientQuery)
        return NewBeneficiaryInfo({
          clientName: c.customer.surName?.trim() + ' ' + c.customer.firstName?.trim(),
          documentType: c.customer.externalIds?.SOE ?? clientData?.externalIds?.SOE ?? '',
          nationalID: c.customer.externalIds?.NATIONAL_ID ?? '',
          issuedBy: '-',
          relationshipWithLifeAssured: c.relationshipType,
          surname: c.customer.surName ?? '',
          givenName: c.customer.firstName ?? '',
          dob: c.customer.dob
            ? PulseOpsFormat.dateStr(c.customer.dob)
            : moment(clientData?.dob).format('DD/MM/YYYY') ?? '',
          gender: c.customer.sex ? c.customer.sex : clientData && clientData.sex ? clientData.sex : '',
          nationality: c.customer.nationality
            ? countries.find((e) => e.code === c.customer.nationality)?.name ?? ''
            : clientData && clientData.nationality
            ? clientData.nationality
            : '',
          mobile: {
            code: '+84',
            number: c.customer.contactDetails.PHONE.value ? `+84 ${c.customer.contactDetails.PHONE.value}` : ''
          },
          email: c.customer.contactDetails.EMAIL.value ?? '',
          percentage: c.percentage
        })
      })
    case SourceType.EKIOSK:
      return detail.payload.body.map((c) => {
        // const clientData = getClientData(c.customer.clientId, clientQuery)
        console.log(detail)
        return NewBeneficiaryInfo({
          clientName: '',
          // documentType: clientData?.externalIds?.SOE ? clientData?.externalIds?.SOE : c.customer.externalIds.SOE_VALUE && markupString(c.customer.externalIds.SOE_VALUE) ? 'National ID' : 'National ID',
          documentType: '-', // No submission data
          nationalID: c.customer.externalIds.SOE_VALUE ? c.customer.externalIds.SOE_VALUE : '-',
          issuedBy: c.customer.externalIds.ISSUED_BY ?? '-',
          relationshipWithLifeAssured: c.relationshipType || '-',
          surname: c.customer.name && c.customer.name.split(' ').length ? getSurname(c.customer.name) : '',
          givenName: c.customer.name ? getGivenName(c.customer.name) : '',
          // dob: c.customer.dob ? moment(c.customer.dob, 'DD/MM/YYYY').format('DD/MM/YYYY') : moment(clientData?.dob).format('DD/MM/YYYY'),
          dob: c.customer.dob ? moment(c.customer.dob, 'DD/MM/YYYY').format('DD/MM/YYYY') : '-',
          // gender: c.customer.sex ? c.customer.sex : (clientData && clientData.sex ? (clientData.sex === 'F' ? 'Female': 'Male') : ''),
          // nationality: c.customer.nationality ? c.customer.nationality : (clientData && clientData.nationality ? clientData.nationality: ''),
          gender: c.customer.sex ? c.customer.sex : '-',
          nationality: c.customer.nationality ? c.customer.nationality : '-',
          // mobile: { //missing data
          //   code: '+84',
          //   number: getClientInfo(client)((client) => client.clientPhoneNumber)
          // },
          // email: getClientInfo(client)((client) => client.email),
          mobile: null,
          email: '-',
          percentage: c.percentage ? Number(c.percentage) : 0
        })
      })
    case SourceType.BANCA:
    case SourceType.PRUGREAT:
      return []
  }
}
