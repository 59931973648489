import { InternalFeedbackStackParamList } from './InternalFeedbackStackParamList'
import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { InternalFeedbackScreen } from './InternalFeedbackScreen'
import { RenewInternalFeedbackScreen } from './RenewInternalFeedbackScreen'

const Stack = createStackNavigator<InternalFeedbackStackParamList>()

export const InternalFeedbackStack = () => {
  return (
    <Stack.Navigator initialRouteName="RenewInternalFeedbackScreen" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="InternalFeedbackScreen" component={InternalFeedbackScreen}></Stack.Screen>
      <Stack.Screen name="RenewInternalFeedbackScreen" component={RenewInternalFeedbackScreen}></Stack.Screen>
    </Stack.Navigator>
  )
}
