import { ZIO } from '@mxt/zio'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { BuildConfig } from './BuildConfig'
import { Maybe } from '@mxt/zio/codec'

export namespace AppConfig {
  export const get = pipe(
    ZIO.fromPromise(() => fetch(`${BuildConfig.contextPath}/static/config.json`).then((res) => res.json())),
    ZIO.decodeThrow(
      t.type({
        env: t.string,
        version: t.string,
        apiUrl: t.string,
        aad: t.type({
          clientId: t.string,
          authority: t.string,
          scopes: t.array(t.string)
        }),
        keycloak: t.type({
          realm: t.string,
          url: t.string,
          clientId: t.string
        }),
        firebase: t.type({
          apiKey: t.string,
          authDomain: t.string,
          projectId: t.string,
          storageBucket: t.string,
          messagingSenderId: t.string,
          appId: t.string,
          measurementId: t.string
        }),
        reCaptchaKey: t.string,
        containerName: t.string,
        OcpApimSubscriptionKey: t.string
      })
    ),
    ZIO.cached()
  )

  const NoticeC = t.type({
    visible: Maybe(t.boolean),
    bgColor: Maybe(t.string),
    textColor: Maybe(t.string),
    messageEn: Maybe(t.string),
    messageVi: Maybe(t.string)
  })

  export type Notice = t.TypeOf<typeof NoticeC>

  export const getGlobalNotices = pipe(
    ZIO.fromPromise(() => fetch(`${BuildConfig.contextPath}/static/notices.config.json`).then((res) => res.json())),
    ZIO.decodeThrow(t.array(NoticeC)),
    ZIO.map((items) => items.filter((i) => i.visible)),
    ZIO.cached()
  )
}
